import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { reactive, computed, watch } from 'vue';
import { useStore } from 'vuex';

// components
import { BaseContainer, BaseGrid, BaseColumn } from '@/components/primitives';
import { BaseButton, BaseSpacer, BaseText } from '@/components/partials';
import { BaseForm, BaseInput } from '@/components/partials/forms';

// helpers
import { HelperFormErrors } from '@/helpers/form';
import { USER_REF_PREFIX, ORDER_REF_PREFIX } from '@/constants/references';

// types
import type { SearchForm } from '@/types/form';
import type { Component, ComputedRef } from 'vue';
import { SearchTypes } from '@/constants/searchTypes';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const store = useStore();

const searchForm: SearchForm = reactive({
  searchPrefix: USER_REF_PREFIX,
  searchTerm: '',
  searchType: 'inbound' as SearchTypes.SEARCH_TYPE_INBOUND,
  errors: new HelperFormErrors(),
});
const loading: ComputedRef<boolean> = computed(() => store.getters['search/getStoreLoading']);
const message: ComputedRef<Component> = computed(() => store.state.search.message);
const search = async () => {
  const searchTerm = `${searchForm.searchPrefix}${searchForm.searchTerm}`;
  const params: SearchForm = Object.assign({}, searchForm, {
    searchTerm,
  });

  await store.dispatch('search/sendRequest', params);
  store.dispatch('page/setReference', searchTerm, { root: true });
};
const setPrefix = (prefix: string): string => (searchForm.searchPrefix = prefix);

// watchers
// @Todo: this could be refactored to use something like a model modifier, but not currently in vuejs
watch(searchForm, () => {
  if (searchForm.searchTerm.length > 0) {
    searchForm.searchTerm = searchForm.searchTerm.replace('-', '');
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), { class: "v-inbound-search" }, {
    default: _withCtx(() => [
      _createVNode(_unref(BaseGrid), {
        gap: "0",
        class: "v-inbound-search__prefix"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseColumn), { span: { default: '6' } }, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseButton), {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (setPrefix(_unref(USER_REF_PREFIX)))),
                ref: "usr-prefix",
                color: searchForm.searchPrefix === _unref(USER_REF_PREFIX) ? 'white' : 'default-alt',
                class: "v-inbound-search__button v-inbound-search__button_left"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" User ")
                ])),
                _: 1
              }, 8, ["color"])
            ]),
            _: 1
          }),
          _createVNode(_unref(BaseColumn), { span: { default: '6' } }, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseButton), {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (setPrefix(_unref(ORDER_REF_PREFIX)))),
                color: searchForm.searchPrefix === _unref(ORDER_REF_PREFIX) ? 'white' : 'default-alt',
                class: "v-inbound-search__button v-inbound-search__button_right"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" Order ")
                ])),
                _: 1
              }, 8, ["color"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(BaseSpacer)),
      _createVNode(_unref(BaseForm), { onFormSubmitted: search }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseGrid), { gap: "0" }, {
            default: _withCtx(() => [
              (searchForm.searchPrefix !== '')
                ? (_openBlock(), _createBlock(_unref(BaseColumn), {
                    key: 0,
                    class: "v-inbound-search__search-prefix",
                    span: { default: '3' }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(BaseText), { ref: "search-prefix" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(searchForm.searchPrefix), 1)
                        ]),
                        _: 1
                      }, 512)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_unref(BaseColumn), { span: { default: '9' } }, {
                default: _withCtx(() => [
                  _createVNode(_unref(BaseInput), {
                    label: searchForm.searchTerm,
                    name: "search-term",
                    value: searchForm.searchTerm,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((searchForm.searchTerm) = $event)),
                    "onReset:errors": _cache[3] || (_cache[3] = ($event: any) => (searchForm.errors.clear('search-term'))),
                    error: searchForm.errors.get('search-term'),
                    type: "number",
                    class: "v-inbound-search__input"
                  }, null, 8, ["label", "value", "error"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (message.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_unref(BaseSpacer)),
                _createVNode(_unref(BaseText), { tags: "p" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(message.value), 1)
                  ]),
                  _: 1
                })
              ], 64))
            : _createCommentVNode("", true),
          _createVNode(_unref(BaseSpacer), { size: "1" }),
          _createVNode(_unref(BaseColumn), null, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseButton), {
                color: "success",
                type: "submit",
                ref: "submit-button",
                "is-loading": loading.value
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Search ")
                ])),
                _: 1
              }, 8, ["is-loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})